<template>
  <Modal
    :shouldShow="shouldShow"
    title="Create Event"
    @closeModal="closeModal"
  >
    <form class="form" v-if="mentorshipModal.first">
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
          <div class="form-group">
            <label for="" class="form__formlabel">Event Name</label>
            <input
              type="text"
              class="form-control
              form__formedit"
              placeholder="E.g Coffee Party"
              v-model="input.name"
            />
            <b-button
              class="btn __modadd"
              style="marginTop: 1rem"
              @click.prevent="addEventDescription = !addEventDescription"
            >
              {{
                `${
                  !addEventDescription
                    ? "Add event description"
                    : "Remove event description"
                }`
              }}
            </b-button>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="addEventDescription">
        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
          <div class="form-group">
            <label for="" class="form__formlabel">Event Description</label>
            <textarea
              name="description"
              class="form-control form__formedit"
              cols="30"
              rows="3"
              v-model="input.description"
            ></textarea>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6" xl="6" lg="6" md="6" sm="12">
          <div class="form-group">
            <label class="form__formlabel">Event Duration</label>
            <input
              type="text"
              class="form-control form__formedit"
              placeholder="1:00"
              v-model="input.duration"
            />
          </div>
        </b-col>
        <b-col cols="6" xl="6" lg="6" md="6" sm="12">
          <div class="form-group">
            <label class="form__formlabel">Event Location</label>
            <select
              name="location"
              class="form-control form__formedit"
              v-model="input.location"
            >
              <option value="in-person">In person meeting</option>
              <option value="digital">Digital</option>
            </select>
          </div>
        </b-col>
      </b-row>

      <!-- <b-row>
        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
          <div class="form-group">
            <label class="form__formlabel">Event Location</label>
            <select
              name="location"
              class="form-control form__formedit"
              v-model="input.location"
            >
              <option value="in-person">In person meeting</option>
              <option value="digital">Digital</option>
            </select>
          </div>
        </b-col>
      </b-row> -->

      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
          <div class="form-group">
            <label for="" class="form__formlabel">Address/Link</label>
            <input
              type="text"
              class="form-control form__formedit"
              placeholder="Specify location for the event"
              v-model="input.link"
            />
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6" xl="8" lg="8" md="6" sm="6"></b-col>
        <b-col cols="6" xl="4" lg="4" md="6" sm="6" class="">
          <a
            href="#"
            class="btn profiledetail__btnsave"
            @click.prevent="setMentorshipModal('second')"
          >
            Next
          </a>
        </b-col>
      </b-row>
    </form>

    <form class="form" v-if="mentorshipModal.second">
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
          <div class="form-group">
            <label class="form__formlabel">Payment</label>
            <select
              name="location"
              class="form-control form__formedit"
              v-model="input.payment_mode"
            >
              <option value="free">Free</option>
              <option value="paid">Paid</option>
            </select>
          </div>
        </b-col>
      </b-row>

      <b-row v-if="input.payment_mode === 'paid'">
        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
          <div class="form-group">
            <label for="" class="form__formlabel">Charges</label>
            <input
              type="text"
              class="form-control form__formedit"
              placeholder="18000"
              v-model="input.cost"
            />
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6" xl="8" lg="8" md="6" sm="6">
          <a
            href="#"
            class="btn profiledetail__cancel"
            @click.prevent="setMentorshipModal('first')"
          >
            Prev
          </a>
        </b-col>
        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
          <a
            href="#"
            class="btn profiledetail__btnsave"
            @click.prevent="setMentorshipModal('third')"
          >
            Next
          </a>
        </b-col>
      </b-row>
    </form>

    <form class="form" v-if="mentorshipModal.third">
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
          <div class="calendar-container">
            <label class="form__formlabel">Select a Date</label>
            <v-date-picker
              mode="date"
              v-model="date"
              @dayclick="setDay"
              title-position="left"
            />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
          <div class="set-durations-container">
            <label>Hours you are available?</label>
            <template v-for="(data, index) of times">
              <div class="time-group" :key="index">
                <div class="single-time-container">
                  <v-date-picker
                    class="custom-time"
                    mode="time"
                    v-model="data.value1"
                    :timezone="timezone"
                    is24hr
                  />
                  -
                  <v-date-picker
                    class="custom-time"
                    mode="time"
                    v-model="data.value2"
                    :timezone="timezone"
                    is24hr
                  />

                  <a
                    href="#"
                    class="btn add-time-btn"
                    @click.prevent="addAnotherTime"
                    v-if="index + 1 == times.length"
                  >
                    Add Time
                  </a>
                  <a
                    href="#"
                    class="remove-time"
                    @click.prevent="removeTime(index)"
                  >
                    <i
                      class="fa fa-times"
                      v-if="index + 1 !== times.length"
                    ></i>
                  </a>
                </div>
              </div>
            </template>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" xl="8" lg="8" md="6" sm="6">
          <a
            href="#"
            class="btn profiledetail__cancel"
            @click.prevent="setMentorshipModal('second')"
          >
            Prev
          </a>
        </b-col>
        <b-col cols="6" xl="4" lg="4" md="6" sm="6" class="">
          <a
            href="#"
            class="btn profiledetail__btnsave"
            @click.prevent="handleUpdateMentorship"
          >
            Save <Loader :shouldShow="updatingMentorship" />
          </a>
        </b-col>
      </b-row>
    </form>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import Modal from "../../components/Modal";
import Loader from "../../components/loader";
import { errorHandler } from "../../../utils/helpers";

export default {
  name: "AddMentorshipSessionModal",
  props: {
    shouldShow: {
      type: Boolean,
      defaultValue: false
    },
    user: {
      type: Object,
      required: false
    }
  },
  components: {
    Modal,
    Loader
  },
  data() {
    return {
      mentorshipModal: { first: true, second: false, third: false },
      input: {},
      addEventDescription: false,
      updatingMentorship: false,
      timezone: "",
      date: new Date(),
      selectedDay: null,
      times: [{ value1: new Date(), value2: new Date() }]
    };
  },
  watch: {
    shouldShow(value) {
      this.shouldShow = value;
    }
  },
  methods: {
    ...mapActions(["createSession"]),
    closeModal() {
      this.$emit("closeModal");
    },
    setDay(day) {
      this.selectedDay = day.id;
    },
    addAnotherTime() {
      this.times.push({
        value1: new Date(),
        value2: new Date()
      });
    },
    removeTime(index) {
      this.times = this.times.filter((_, idx) => idx !== index);
    },
    setMentorshipModal(value) {
      for (let key in this.mentorshipModal) {
        if (key === value) {
          this.mentorshipModal[key] = true;
        } else {
          this.mentorshipModal[key] = false;
        }
      }
    },
    async handleUpdateMentorship() {
      this.updatingMentorship = true;
      try {
        let times = "";
        if (this.times.length > 1) {
          const validTimes = [...this.times].splice(0, this.times.length - 1);
          times = validTimes.map(time => {
            let startTime = String(time.value1)
              .split(" ")[4]
              .substr(
                0,
                String(time.value1)
                  .split(" ")[4]
                  .lastIndexOf(":")
              );

            let stopTime = String(time.value2)
              .split(" ")[4]
              .substr(
                0,
                String(time.value2)
                  .split(" ")[4]
                  .lastIndexOf(":")
              );

            return `${startTime}-${stopTime}`;
          });
        }
        const payload = { ...this.input, times, date: this.selectedDay };

        const result = await this.createSession(payload);

        this.$toast.success(`${result.message}`);
        this.times = [{ value1: new Date(), value2: new Date() }];
        this.input = {};
        this.setMentorshipModal("first");
        this.showMentorshipModal = false;
      } catch (error) {
        errorHandler(error, this.$$toast);
      } finally {
        this.updatingMentorship = false;
      }
    },
    formatDate(date) {
      const specifiedData = new Date(date);
      let month = "" + (specifiedData.getMonth() + 1),
        day = "" + specifiedData.getDate(),
        year = specifiedData.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    }
  },
  created() {
    this.selectedDay = this.formatDate(new Date());
  }
};
</script>

<style lang="scss">
.time-group {
  display: flex;
  align-items: center;
}
.custom-time {
  display: inline-block;
  width: 149px !important;
}

.set-durations-container {
  border: 1px solid #9a9a9a;
  border-radius: 5px;
  min-height: 154px;
  margin-top: 12px;

  & > label {
    font-size: 16px;
    font-family: "CircularStd-Book";
    font-weight: normal;
    font-style: normal;
    color: #000000;
    padding: 10px 17px 8px;
  }
}

.add-time-btn {
  background-color: #2357bb;
  color: #ffffff !important;
  padding: 2px 12px;
  border-radius: 5px;
}

.single-time-container {
  & .remove-time {
    color: gray;
    font-size: 13px;
  }
}

.vc-highlights + span[tabindex="0"] {
  background-color: #2357bb;
}
</style>
