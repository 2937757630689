<template>
  <Modal :shouldShow="shouldShow" title="About me" @closeModal="closeModal">
    <template v-if="userData">
      <form class="form">
        <b-row>
          <b-col cols="12" xl="6" lg="6" md="6">
            <div class="form-group profile-edit-form-group">
              <label class="form__formlabel profile-edit-form-label">
                First Name
              </label>
              <input
                type="text"
                class="form-control form__formedit"
                placeholder="Carla"
                v-model="userData.firstName"
              />
            </div>
          </b-col>

          <b-col cols="12" xl="6" lg="6" md="6">
            <div class="form-group profile-edit-form-group">
              <label class="form__formlabel">Last Name</label>
              <input
                type="text"
                class="form-control form__formedit"
                placeholder="Echevarria"
                v-model="userData.lastName"
              />
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" xl="4" lg="4" md="4">
            <div class="form-group">
              <label
                for="my-file"
                class="btn update-user-avatar-btn"
                style="width: 100%"
              >
                Edit Picture
              </label>
                <input
                  id="my-file"
                  type="file"
                  @change="onFileChange"
                  class="form-control-file uploadimages__controfile"
                />
                <span style="fontSize: 14px">
                  {{ file && file.name }}
                </span>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" xl="12" lg="12" md="12">
            <div class="form-group profile-edit-form-group">
              <label class="form__formlabel profile-edit-form-label">Phone</label>
              <input
                type="text"
                class="form-control form__formedit"
                placeholder="23470384593823"
                v-model="userData.phone"
              />
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" xl="12" lg="12" md="12">
            <div class="form-group profile-edit-form-group">
              <label class="form__formlabel profile-edit-form-label">
                Username
              </label>
              <input
                type="text"
                class="form-control form__formedit"
                placeholder="Echevarria19"
                v-model="userData.slug"
              />
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6" xl="8" lg="8" md="6" sm="6"></b-col>
          <b-col cols="6" xl="4" lg="4" md="6" sm="6" class="">
            <a
              href="#"
              class="btn profiledetail__btnsave"
              @click.prevent="handleUpdateAboutMe"
              >Save <Loader :shouldShow="shouldShowLoader" />
            </a>
          </b-col>
        </b-row>
      </form>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";

import Modal from "../../components/Modal";
import Loader from "../../components/loader";
import { errorHandler } from "../../../utils/helpers";

export default {
  name: "AboutUserModal",
  components: {
    Modal,
    Loader
  },
  props: {
    shouldShow: {
      type: Boolean,
      defaultValue: false
    },
    user: {
      type: Object,
      required: false
    },
    slug: {
      type: String,
      required: true
    }
  },
  watch: {
    shouldShow(value) {
      this.shouldShow = value;
    },
    user(value) {
      this.userData = { ...value };
      const [firstName, lastName] = value.fullName.split(" ");
      this.userData.firstName = firstName;
      this.userData.lastName = lastName;
    }
  },
  data() {
    return {
      userData: null,
      shouldShowLoader: false,
      file: null,
    };
  },
  methods: {
    ...mapActions(["updateUserProfile"]),
    closeModal() {
      this.$emit("closeModal");
    },
    async handleUpdateAboutMe() {
      this.shouldShowLoader = true;
      try {
        const { firstName, lastName, phone, avatar, slug } = this.userData;
        const fullName = `${firstName} ${lastName}`;
        this.userData.fullName = fullName;

        const result = await this.updateUserProfile({
          payload: { fullName, phone, avatar, slug, user_id: this.user.id }
        });

        localStorage.setItem(
          "book_me-user-about",
          JSON.stringify(this.userAbout)
        );

        this.$toast.success(`${result.message}`);
        this.$emit("closeModal");
        if(!(this.slug === this.user.slug)) {
          this.$router.push(`/${this.user.slug}/profile-edit`);
        }
      } catch (error) {
        errorHandler(error, this.$toast);
      } finally {
       this.shouldShowLoader = false;
      }
    },
    async onFileChange(event) {
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      this.file = files[0];
      this.userData.avatar = await this.toBase64(files[0]);
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  }
};
</script>

<style lang="scss">
input[id="my-file"] {
  position: absolute;
  top: -1000px;
}
.update-user-avatar-btn {
  background-color: #2357bb;
  color: #ffffff !important;
  margin-top: 1rem;
  margin-bottom: 0 !important;
}

</style>
