<template>
  <Modal :shouldShow="shouldShow" :title="`Delete ${itemType}`" @closeModal="closeModal">
    Are you sure you want to delete this {{ itemType }} <strong>{{ sessionToDelete }}</strong>.

    <b-row>
      <b-col cols="6" xl="8" lg="8" md="6" sm="6">
        <a
          href="#"
          class="btn profiledetail__cancel"
          @click.prevent="closeModal"
        >
          Cancel
        </a>
      </b-col>
      <b-col cols="6" xl="4" lg="4" md="6" sm="6" class="">
        <a
          href="#"
          class="btn profiledetail__btnsave d-flex"
          @click.prevent="handleDelete"
        >
          Delete <Loader :shouldShow="showDeleteLoader" />
        </a>
      </b-col>
    </b-row>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import Modal from "../../components/Modal";
import Loader from "../../components/loader";

export default {
  name: "DeleteModal",
  components: {
    Modal,
    Loader
  },
  props: {
    shouldShow: {
      type: Boolean,
      defaultValue: false
    },
    itemType: {
      type: String,
      required: true
    },
    sessionToDelete: {
      type: String,
      required: true
    },
    showDeleteLoader: {
      type: Boolean,
      defaultValue: false
    }
  },
  watch: {
    shouldShow(value) {
      this.shouldShow = value;
    },
    showDeleteLoader(value) {
      this.showDeleteLoader = value;
    }
  },
  methods: {
    ...mapActions(["updateUserProfile"]),
    closeModal() {
      this.$emit("closeModal");
    },

    handleDelete() {
      this.$emit('deleteItem')
    }
  }
};
</script>

<style lang="scss">
input[id="my-file"] {
  position: absolute;
  top: -1000px;
}
.update-user-avatar-btn {
  background-color: #2357bb;
  color: #ffffff !important;
  margin-top: 1rem;
  margin-bottom: 0 !important;
}
</style>