<template>
  <p>
    {{ label }}
    <img
      src="../assets/img/remove.png"
      @click="removeChip"
      v-show="isCloseable"
    />
  </p>
</template>

<script>
export default {
  name: "Chip",
  props: {
    label: {
      type: String,
      required: true
    },
    isCloseable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    removeChip() {
      this.$emit("removeItem");
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  margin-top: 0;
  margin: 0 8px 8px 0;
  display: inline-flex;
  font-size: 14px;
  color: #2357bb;
  justify-content: space-between;
  align-items: center;
  background-color: #e5ebf5;
  border-radius: 5px;
  min-width: 80px;
  height: 30px;
  padding: 5px;
  text-align: center;

  img {
    width: 14px;
    height: 14px;
    cursor: ß;
  }
}
</style>
