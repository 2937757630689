import { render, staticRenderFns } from "./AddSocialsModal.vue?vue&type=template&id=43c524c3&"
import script from "./AddSocialsModal.vue?vue&type=script&lang=js&"
export * from "./AddSocialsModal.vue?vue&type=script&lang=js&"
import style0 from "./AddSocialsModal.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports