<template>
  <div class="modal-container" v-if="show">
    <div class="modal-content">
      <template v-if="title">
        <div class="title-and-close-btn">
          <p class="__projp modal-title">{{ title }}</p>
          <i class="fa fa-times" @click.prevent="closeModal"></i>
        </div>
      </template>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    shouldShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      show: false
    };
  },
  watch: {
    shouldShow(val) {
      this.show = val;
    }
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    }
  }
};
</script>

<style lang="scss">
.modal-container {
  background-color: rgba(225, 225, 225, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 1000;
}
.title-and-close-btn {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #9a9a9a;
  margin-bottom: 10px;

  & > i {
    font-size: 13px;
    cursor: pointer;
  }
}
.modal-title {
  font-family: "CircularStd-Bold";
  font-size: 20px;
}

.modal-content {
  max-width: 679px;
  width: 50%;
  border-radius: 10px;
  box-shadow: -10px -2px 63px rgba(0, 0, 0, 0.08);
  background: #FFFFFF;
  padding: 1rem;
}
</style>
