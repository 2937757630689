<template>
  <div>
    <p class="__empname">{{ label }}</p>
    <div class="__empind chip-container" style="padding: 10px!important;">
      <div
        style="display:flex;flexDirection: column; justifyContent: space-between"
      >
        <div>
          <template v-if="arrValues.length">
            <template v-for="(data, index) of arrValues">
              <Chip
                :label="data"
                @removeItem="removeChip(index)"
                :key="index"
                :isCloseable="true"
              />
            </template>
          </template>
          <p v-if="!arrValues.length" style="fontSize: 12px">{{placeholder}}</p>
        </div>

        <input
          type="text"
          class="custom-input"
          @change="handleTextInput"
          placeholder="Type here..."
          tabindex="1"
          autofocus
        />
      </div>
    </div>
  </div>
</template>

<script>
import Chip from "./chip";

export default {
  name: "InputWithChip",
  components: {
    Chip,
  },
  props: ["label", "existingValues", "placeholder"],
  data() {
    return {
      arrValues: this.existingValues || []
    };
  },
  methods: {
    handleTextInput(event) {
      this.arrValues.push(event.target.value);
      event.target.value = "";
      this.$emit("updateCustomList", this.arrValues.join(", "));
    },
    removeChip(index) {
      this.arrValues = this.arrValues.filter((_, idx) => index !== idx);
      this.$emit("updateCustomList", this.arrValues.join(", "));
    }
  }
};
</script>

<style lang="scss">

.chip-container {
  display: flex;
  flex-wrap: wrap;
  min-height: 200px;
  border: 1px solid #eee;
  border-radius: 5px;
}

.custom-input {
  border: none;
  height: 40px;
  width: 100px;
  background-color: #eee;
  height: 30px;
  border-radius: 5px;
  width: 160px;
  padding: 5px;
  font-size: 12px;
  &:focus {
    border: none;
    outline: none;
  }
}
</style>
