<template>
  <Modal :shouldShow="shouldShow" title="Bio" @closeModal="closeModal">
    <template v-if="profile">
      <form class="form" v-if="!showBio2">
        <b-row>
          <b-col cols="12" xl="12" lg="12" md="12">
            <div class="form-group profile-edit-form-group">
              <label class="form__formlabel profile-edit-form-label">
                Add a description about yourself
              </label>

              <textarea
                class="form-control form__formedit"
                rows="5"
                style="fontSize: 12px; color:rgba(43, 43, 43, 0.6);"
                v-model="profile.bio"
              >
              </textarea>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xl="12" lg="12" md="12">
            <div class="form-group profile-edit-form-group">
              <label class="form__formlabel profile-edit-form-label">
                Gender
              </label>
              <select
                name="gender"
                class="form-control form__formedit"
                v-model="profile.gender"
              >
                <option value="male" :selected="profile.gender === 'male'">
                  Male
                </option>
                <option value="female" :selected="profile.gender === 'female'">
                  Female
                </option>
                <option value="others" :selected="profile.gender === 'others'">
                  Others
                </option>
              </select>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xl="12" lg="12" md="12">
            <div class="form-group profile-edit-form-group">
              <label for="" class="form__formlabel">Country</label>
              <select
                class="form-control form__formedit"
                v-model="selectedCountry"
                @change="handleChangeCountry"
              >
                <option value="" :disabled="countries.length" hidden selected>{{
                  `${existingCountry ? existingCountry.name : ""}`
                }}</option>
                <template v-for="(country, index) of countries">
                  <option
                    :value="country.isoCode"
                    :key="index"
                    :selected="country.isoCode == usersCountry"
                  >
                    {{ country.name }}
                  </option>
                </template>
              </select>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xl="6" lg="6" md="6">
            <div class="form-group profile-edit-form-group">
              <label for="" class="form__formlabel">State</label>
              <select
                class="form-control form__formedit"
                v-model="selectedState"
                @change="handleChangeState"
              >
                <option value="" selected hidden :disabled="states.length">{{
                  `${existingState ? existingState.name : ""}`
                }}</option>
                <template v-for="(state, index) of states">
                  <option
                    :value="state.isoCode"
                    :key="index"
                    :selected="index === 0 ? 'selected' : ''"
                  >
                    {{ state.name }}
                  </option>
                </template>
              </select>
            </div>
          </b-col>
          <b-col cols="12" xl="6" lg="6" md="6">
            <div class="form-group profile-edit-form-group">
              <label for="" class="form__formlabel">City</label>
              <select
                class="form-control form__formedit"
                v-model="selectedCity"
              >
                <option value="" selected hidden :disabled="cities.length">{{
                  `${existingCity ? existingCity : ""}`
                }}</option>
                <template v-for="(city, index) of cities">
                  <option
                    :value="city.name"
                    :key="index"
                    :selected="city.name === usersCity"
                  >
                    {{ city.name }}
                  </option>
                </template>
              </select>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6" xl="8" lg="8" md="6" sm="6"></b-col>
          <b-col cols="6" xl="4" lg="4" md="6" sm="6" class="">
            <a
              href="#"
              class="btn profiledetail__btnsave"
              @click.prevent="showBio2 = true"
            >
              Next
            </a>
          </b-col>
        </b-row>
      </form>

      <form class="form" v-if="showBio2">
        <b-row>
          <b-col cols="12" xl="12" lg="12" md="12">
            <div class="form-group profile-edit-form-group">
              <InputWithChip
                @updateCustomList="updateSkills"
                label="Expertise"
                :existingValues="
                  profile.skills ? profile.skills.split(',') : []
                "
                placeholder="Please add your areas of expertise"
              />
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" xl="12" lg="12" md="12">
            <div class="form-group profile-edit-form-group">
              <label class="form__formlabel profile-edit-form-label">
                Charges per hour <span style="fontSize: 10px">(That you will paid for the session)</span>
              </label>
              <input
                type="text"
                class="form-control form__formedit"
                placeholder="1000"
                v-model="profile.work_rate"
              />
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" xl="12" lg="12" md="12">
            <div class="form-group profile-edit-form-group">
              <label class="form__formlabel profile-edit-form-label">
                Work preference
              </label>
              <select
                name="gender"
                class="form-control form__formedit"
                v-model="profile.work_preferences"
              >
                <option
                  value="full-time"
                  :selected="profile.work_preferences === 'full-time'"
                >
                  Full time
                </option>
                <option
                  value="part-time"
                  :selected="profile.work_preferences === 'contract'"
                >
                  Contract
                </option>
              </select>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="7" xl="6" lg="6" md="6" sm="6">
            <p class="__availb">
              Available for work?
              <label class="switch" for="checkboxav">
                <input
                  type="checkbox"
                  class="switch_1"
                  @change="workExp = !workExp"
                  :checked="profile.work_available === 1"
                />
                <div class="slider round"></div>
              </label>
            </p>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="6" xl="8" lg="8" md="6" sm="6">
            <a
              href="#"
              class="btn profiledetail__cancel"
              @click.prevent="showBio2 = false"
            >
              Prev
            </a>
          </b-col>
          <b-col cols="6" xl="4" lg="4" md="6" sm="6" class="">
            <a
              href="#"
              class="btn profiledetail__btnsave d-flex"
              @click.prevent="handleUpdateUserBio"
            >
              Save <Loader :shouldShow="updatingUserBio" />
            </a>
          </b-col>
        </b-row>
      </form>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import csc from "country-state-city";

import Modal from "../../components/Modal";
import InputWithChip from "../../components/input-with-chip";
import Loader from "../../components/loader";
import { errorHandler } from "../../../utils/helpers";

export default {
  name: "UserBioModal",
  components: {
    Modal,
    InputWithChip,
    Loader
  },
  data() {
    return {
      profile: null,
      shouldShowLoader: false,
      updatingUserBio: false,
      showBio2: false,

      usersCountry: null,
      usersState: null,
      usersCity: null,
      countries: [],
      states: [],
      cities: [],
      selectedCountry: "",
      selectedState: "",
      selectedCity: "",
      userSelectedCountry: null,
      userSelectedState: null,
      existingCountry: null,
      existingCity: null,
      existingState: null,
      selectedCountryName: "",
      selectedStateName: "",
      workExp: false
    };
  },
  props: {
    shouldShow: {
      type: Boolean,
      defaultValue: false
    },
    user: {
      type: Object,
      required: false
    }
  },
  watch: {
    shouldShow(value) {
      this.shouldShow = value;
    },
    user(value) {
      this.profile = { ...value.profile };
      this.skills = this.profile.skills || "";
      this.workExp = value.profile.work_available;

      if (this.profile.country) {
        const { country, state, city } = this.profile;
        this.existingCountry = this.countries.find(
          data => data.name == country
        );
        this.states = csc.getStatesOfCountry(`${this.existingCountry.isoCode}`);
        this.existingState = this.states.find(data => data.name == state);

        this.cities = csc.getCitiesOfState(
          `${this.existingCountry.isoCode}`,
          `${this.existingState.isoCode}`
        );

        this.existingCity = city;
      }
    }
  },
  methods: {
    ...mapActions(["updateUserBio"]),
    closeModal() {
      this.$emit("closeModal");
    },
    handleChangeCountry(event) {
      if (event.target.value) {
        this.states = csc.getStatesOfCountry(`${event.target.value}`);
        this.selectedCountryName = csc.getCountryByCode(
          `${event.target.value}`
        )["name"];
      } else {
        this.states = [];
      }
    },
    handleChangeState(event) {
      if (event.target.value) {
        this.cities = csc.getCitiesOfState(
          `${this.selectedCountry}`,
          `${this.selectedState}`
        );
        let state = this.states.find(
          state => state.isoCode === this.selectedState
        );
        this.selectedStateName = state.name;
      } else {
        this.cities = [];
      }
    },
    updateSkills(value) {
      this.skills = value;
    },
    async handleUpdateUserBio() {
      this.updatingUserBio = true;
      try {
        const { bio, gender, work_preferences, work_rate } = this.profile;

        const payload = {
          work_available: this.workExp ? 1:0,
          skills: this.skills,
          bio,
          gender,
          work_preferences,
          work_rate,
          country: this.selectedCountryName ? this.selectedCountryName : this.existingCountry?.name,
          state: this.selectedStateName ? this.selectedStateName : this.existingState?.name,
          city: this.selectedCity || this.existingCity
        };

        const { message, data } = await this.updateUserBio(payload);

        this.profile = data;
        this.$toast.success(`${message}`);

        this.closeModal();

        this.showBio2 = false;
      } catch (error) {
        errorHandler(error, this.$toast);
      } finally {
        this.updatingUserBio = false;
      }
    }
  },
  created() {
    this.countries = csc.getAllCountries();
  }
};
</script>

<style lang="scss">
.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
