<template>
  <Modal :shouldShow="shouldShow" title="Socials" @closeModal="closeModal">
    <template v-if="userData">
      <form class="form">
        <b-row>
          <b-col cols="12" xl="6" lg="6" md="6">
            <div class="form-group profile-edit-form-group">
              <label class="form__formlabel profile-edit-form-label">
                Facebook
              </label>
              <input
                type="text"
                class="form-control form__formedit"
                placeholder="https://facebook.com/user-name"
                v-model="userData.profile.facebook_url"
              />
            </div>
          </b-col>

          <b-col cols="12" xl="6" lg="6" md="6">
            <div class="form-group profile-edit-form-group">
              <label class="form__formlabel">Instagram</label>
              <input
                type="text"
                class="form-control form__formedit"
                placeholder="https://instagram.com/user-name"
                v-model="userData.profile.instagram_url"
              />
            </div>
          </b-col>
        </b-row>

				<b-row>
          <b-col cols="12" xl="6" lg="6" md="6">
            <div class="form-group profile-edit-form-group">
              <label class="form__formlabel profile-edit-form-label">
                LinkedIn
              </label>
              <input
                type="text"
                class="form-control form__formedit"
                placeholder="https:/linkedin.com/in/user-name"
                v-model="userData.profile.linkedin_url"
              />
            </div>
          </b-col>

          <b-col cols="12" xl="6" lg="6" md="6">
            <div class="form-group profile-edit-form-group">
              <label class="form__formlabel">Twitter</label>
              <input
                type="text"
                class="form-control form__formedit"
                placeholder="https://twitter.com/user-name"
                v-model="userData.profile.twitter_url"
              />
            </div>
          </b-col>
        </b-row>


        <b-row>
          <b-col cols="6" xl="8" lg="8" md="6" sm="6"></b-col>
          <b-col cols="6" xl="4" lg="4" md="6" sm="6" class="">
            <a
              href="#"
              class="btn profiledetail__btnsave"
              @click.prevent="handleUpdateSocials"
              >Save <Loader :shouldShow="shouldShowLoader" />
            </a>
          </b-col>
        </b-row>
      </form>
    </template>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";

import Modal from "../../components/Modal";
import Loader from "../../components/loader";
import { errorHandler } from "../../../utils/helpers";

export default {
  name: "AddSocialsModal",
  components: {
    Modal,
    Loader
  },
  props: {
    shouldShow: {
      type: Boolean,
      defaultValue: false
    },
    user: {
      type: Object,
      required: false
    },
    slug: {
      type: String,
      required: true
    }
  },
  watch: {
    shouldShow(value) {
      this.shouldShow = value;
    },
    user(value) {
      this.userData = { ...value };
    }
  },
  data() {
    return {
      userData: null,
      shouldShowLoader: false,
      file: null,
    };
  },
  methods: {
    ...mapActions(["updateUserBio"]),
    closeModal() {
      this.$emit("closeModal");
    },
    async handleUpdateSocials() {
      this.shouldShowLoader = true;
      try {
        delete this.userData.profile.created_at;
        delete this.userData.profile.updated_at;

        const result = await this.updateUserBio({...this.userData.profile });

        this.$toast.success(`${result.message}`);
        this.$emit("closeModal");
        if(!(this.slug === this.user.slug)) {
          this.$router.push(`/${this.user.slug}/profile-edit`);
        }
      } catch (error) {
        errorHandler(error, this.$toast);
      } finally {
       this.shouldShowLoader = false;
      }
    },
    async onFileChange(event) {
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) return;

      this.file = files[0];
      this.userData.avatar = await this.toBase64(files[0]);
    },
    async toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
  }
};
</script>

<style lang="scss">
input[id="my-file"] {
  position: absolute;
  top: -1000px;
}
.update-user-avatar-btn {
  background-color: #2357bb;
  color: #ffffff !important;
  margin-top: 1rem;
  margin-bottom: 0 !important;
}

</style>
