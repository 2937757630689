<template>
  <Modal
    :shouldShow="shouldShow"
    title="Update Session"
    @closeModal="closeModal"
  >

  <b-row>
    <b-col cols="6" xl="6" lg="6" md="6" sm="12" style="textAlign: center;" @click="selectTab('mentorship')">
      <label for="" class="form__formlabel" style="cursor: pointer;">Mentorship <span class="active-tab" v-if="showMentorshipTab"></span></label>
    </b-col>
    <b-col cols="6" xl="6" lg="6" md="6" sm="12" style="textAlign: center;" @click="selectTab('schedule')">
      <label for="" class="form__formlabel" style="cursor: pointer;">Schedule <span class="active-tab" v-if="showScheduleTab"></span></label>
    </b-col>
  </b-row>

  <form class="form" v-if="showMentorshipTab && mentorshipModal.first">
    <b-row>
      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
        <div class="form-group">
          <label for="" class="form__formlabel">Session Name</label>
          <input
            type="text"
            class="form-control form__formedit"
            placeholder="E.g Coffee Party"
            style="marginBottom: 1rem"
            v-model="input.name"
          />
          <b-button
            class="btn __modadd"
            @click.prevent="addEventDescription = !addEventDescription"
          >
            {{
              `${
                (!addEventDescription)
                  ? "Add event description"
                  : "Remove event description"
              }`
            }}
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="addEventDescription">
      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
        <div class="form-group">
          <label for="" class="form__formlabel">Event Description</label>
          <textarea
            name="description"
            class="form-control form__formedit"
            cols="30"
            rows="3"
            v-model="input.description"
          ></textarea>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6" xl="6" lg="6" md="6" sm="12">
        <div class="form-group">
          <label class="form__formlabel">Event Duration</label>
          <input
            type="text"
            class="form-control form__formedit"
            placeholder="1:00"
            v-model="input.duration"
          />
        </div>
      </b-col>
      <b-col cols="6" xl="6" lg="6" md="6" sm="12">
        <div class="form-group">
          <label class="form__formlabel">Event Location</label>
          <select
            name="location"
            class="form-control form__formedit"
            v-model="input.location"
          >
            <option value="in-person">In person meeting</option>
            <option value="digital">Digital</option>
          </select>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
        <div class="form-group">
          <label for="" class="form__formlabel">Address/Link</label>
          <input
            type="text"
            class="form-control form__formedit"
            placeholder="Specify location for the event"
            v-model="input.link"
          />
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6" xl="8" lg="8" md="6" sm="6"></b-col>
      <b-col cols="6" xl="4" lg="4" md="6" sm="6" class="">
        <a
          href="#"
          class="btn profiledetail__btnsave"
          @click.prevent="setMentorshipModal('second')"
        >
          Next
        </a>
      </b-col>
    </b-row>
  </form>
  
  <form class="form" v-if="showMentorshipTab && mentorshipModal.second">
    <b-row>
      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
        <div class="form-group">
          <label class="form__formlabel">Payment</label>
          <select
            name="location"
            class="form-control form__formedit"
            v-model="input.payment_mode"
          >
            <option value="free">Free</option>
            <option value="paid">Paid</option>
          </select>
        </div>
      </b-col>
    </b-row>

    <b-row v-if="input.payment_mode === 'paid'">
      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
        <div class="form-group">
          <label for="" class="form__formlabel">Charges</label>
          <input
            type="text"
            class="form-control form__formedit"
            placeholder="18000"
            v-model="input.cost"
          />
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6" xl="8" lg="8" md="6" sm="6">
        <a
          href="#"
          class="btn profiledetail__cancel"
          @click.prevent="setMentorshipModal('first')"
        >
          Prev
        </a>
      </b-col>
      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
        <a
          href="#"
          class="btn profiledetail__btnsave"
          @click.prevent="handleUpdateMentorship"
        >
          Update <Loader :shouldShow="updatingMentorship" />
        </a>
      </b-col>
    </b-row>
  </form>

  <form class="form" style="marginTop: 1rem;" v-if="showScheduleTab">
    <b-row>
      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
        <div class="calendar-container">
          <label class="form__formlabel">Select a Date</label>
          <v-date-picker mode="date" v-model="date" @dayclick="setDay" />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
        <div class="set-durations-container">
          <label>Hours you are available?</label>
          <template v-for="(data, index) of times">
            <div class="time-group" :key="index">
              <div class="single-time-container">
                <v-date-picker
                  class="custom-time"
                  mode="time"
                  v-model="data.value1"
                  :timezone="timezone"
                  is24hr
                />
                -
                <v-date-picker
                  class="custom-time"
                  mode="time"
                  v-model="data.value2"
                  :timezone="timezone"
                  is24hr
                />

                <a
                  href="#"
                  class="btn add-time-btn"
                  @click.prevent="addAnotherTime"
                  v-if="index + 1 == times.length"
                >
                  Add Time
                </a>
                <a
                  href="#"
                  class="remove-time"
                  @click.prevent="removeTime(index)"
                >
                  <i
                    class="fa fa-times"
                    v-if="index + 1 !== times.length"
                  ></i>
                </a>
              </div>
            </div>
          </template>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="6" xl="8" lg="8" md="6" sm="6"></b-col>
      <b-col cols="6" xl="4" lg="4" md="6" sm="6">
        <a
          href="#"
          class="btn profiledetail__btnsave"
          @click.prevent="handleUpdateSchedule"
        >
          Update <Loader :shouldShow="updatingSchedule" />
        </a>
      </b-col>
    </b-row>
  </form>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
import Modal from "../../components/Modal";
import Loader from "../../components/loader";
import { errorHandler } from "../../../utils/helpers";

export default {
  name: "UpdateMentorshipSessionModal",
  props: {
    shouldShow: {
      type: Boolean,
      defaultValue: false,
    },
    selectedSession: {
      type: [Object, String],
      required: true
    }
  },
  components: {
    Modal,
    Loader,
  },
  data() {
    return {
      mentorshipModal: { first: true, second: false, third: false },
      input: {},
      addEventDescription: false,
      updatingMentorship: false,
      timezone: "",
      date: new Date(),
      selectedDay: null,
      times: [],
      showMentorshipTab: true,
      showScheduleTab: false,
      updatingSchedule: false
    };
  },
  watch: {
    shouldShow(value) {
      this.shouldShow = value;
    },
    selectedSession(value) {
      const timeArr = value.schedule.times.split(',').map(value => value.split('-'));
      this.date = this.formatDate(new Date(`${this.selectedSession.schedule.date}`));
      this.selectedDay = `${this.selectedSession.schedule.date}`;

      if(this.selectedSession.description && this.selectedSession.description.length) {
        this.addEventDescription = true;
      }

      if (timeArr.length) {
        for(const [first, second] of timeArr) {
          this.times.push({
            value1: new Date(`${value.schedule.date} ${first}`),
            value2: new Date(`${value.schedule.date} ${second}`),
          });
        }
      }

      this.times.push({
        value1: new Date(),
        value2: new Date(),
      });

      this.input = value;
    }
  },
  methods: {
    ...mapActions(["updateMentorshipSession", "updateMentorshipSchedule"]),
    closeModal() {
      this.$emit("closeModal");
    },
    setDay(day) {
      this.selectedDay = day.id;
    },
    addAnotherTime() {
      this.times.push({
        value1: new Date(),
        value2: new Date(),
      });
    },
    removeTime(index) {
      this.times = this.times.filter((_, idx) => idx !== index);
    },
    setMentorshipModal(value) {
      for (let key in this.mentorshipModal) {
        if (key === value) {
          this.mentorshipModal[key] = true;
        } else {
          this.mentorshipModal[key] = false;
        }
      }
    },
    async handleUpdateMentorship() {
      try {
        this.updatingMentorship = true;
        const payload = { ...this.input };
        payload.mentorship_id = payload.id;
        delete payload.schedule;
        delete payload.created_at;
        delete payload.updated_at;


        if(payload.payment_mode.toLowerCase() === 'free') {
          payload.cost = 0;
        }
        
        const message = await this.updateMentorshipSession(payload);

        this.$toast.success(`${message}`);
        this.setMentorshipModal("first");
        this.closeModal();
      } catch (error) {
        errorHandler(error, this.$toast);
      } finally {
        this.updatingMentorship = false;
      }
    },
    async handleUpdateSchedule() {
      try {
        let times = "";
        this.updatingSchedule = true;
        if (this.times.length > 1) {
          const validTimes = [...this.times].splice(0, this.times.length - 1);
          times = validTimes.map((time) => {
            let startTime = String(time.value1)
              .split(" ")[4]
              .substr(0, String(time.value1).split(" ")[4].lastIndexOf(":"));

            let stopTime = String(time.value2)
              .split(" ")[4]
              .substr(0, String(time.value2).split(" ")[4].lastIndexOf(":"));

            return `${startTime}-${stopTime}`;
          });
        }

        const payload = { ...this.input.schedule, times, date: this.selectedDay };

        delete payload.created_at;
        delete payload.updated_at;
        payload.schedule_id = payload.id;

        const message = await this.updateMentorshipSchedule(payload);

        this.$toast.success(`${message}`);
        this.closeModal();

      } catch (error) {
        errorHandler(error, this.$toast);
      } finally {
        this.updatingSchedule = false;
      }
    },
    formatDate(date) {
      const specifiedData = new Date(date);
      let month = "" + (specifiedData.getMonth() + 1),
        day = "" + specifiedData.getDate(),
        year = specifiedData.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    selectTab(value) {
      this.showMentorshipTab = value === 'mentorship' ? true: false;
      this.showScheduleTab = !this.showMentorshipTab;

      if(!this.showScheduleTab) this.setMentorshipModal("first")
    }
  }
};
</script>

<style lang="scss" scoped>
.vc-container {
  width: 100% !important;
  border: none !important;
}

.calendar-container {
  border: 1px solid #9a9a9a;
  padding: 0 10px;
  border-radius: 5px;

  & > label {
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
}

.vc-time-picker > div:first-child {
  display: none;
}
.vc-date {
  display: none !important;
}
.time-group {
  display: flex;
  align-items: center;
}
.custom-time {
  display: inline-block;
  width: 149px !important;
}
.vc-day-content:focus {
  background-color: #2357bb !important;
  color: #ffffff !important;
}
.vc-header {
  position: relative;
  justify-content: unset !important;
  background-color: rgba(154, 154, 154, 0.1);
  padding: 10px 18px 10px 18px !important;
  width: 90%;
  margin: 0 auto;
  border-radius: 5px;

  & > div.vc-title {
    font-size: 16px !important;
    font-family: "CircularStd-Book";
    font-style: normal;
    font-weight: normal;
    color: #000000;
  }
}
.vc-arrows-container {
  width: 12%;
  left: 82%;
  top: 3px;
  & > div {
    border: 1px solid #2357bb;
    border-radius: 50%;
    width: 22px;
    height: 22px;

    & > svg {
      width: 15px;
      height: 15px;
    }
  }
}

.set-durations-container {
  border: 1px solid #9a9a9a;
  border-radius: 5px;
  min-height: 154px;
  margin-top: 12px;

  & > label {
    font-size: 16px;
    font-family: "CircularStd-Book";
    font-weight: normal;
    font-style: normal;
    color: #000000;
    padding: 10px 17px 8px;
  }
}

.add-time-btn {
  background-color: #2357bb;
  color: #ffffff !important;
  padding: 2px 12px;
  border-radius: 5px;
}

.single-time-container {
  & .remove-time {
    color: gray;
    font-size: 13px;
  }
}

.vc-highlights + span[tabindex="0"] {
  background-color: #2357bb;
}

.active-tab {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: #2357BB;
  border-radius: 50%;
}
</style>