<template>
  <div>
    <topHeader :userData="user" />
    <section>
      <div class="container">
        <div class="profiledetail">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-5">
              <div class="__fixedimg" style="margin-top: 0">
                <template v-if="user">
							    <profileCarousel :user="user" :loggedInUser="user" />
                </template>

                <template v-if="user.profile.facebook_url|| user.profile.twitter_url || user.profile.instagram_url || user.profile.linkedin_url">
                  <hr />
                  <div class="row">
                    <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-xs-10">
                      <a :href="`${user.profile.twitter_url}`" target="_blank" v-if="user.profile.twitter_url">
                        <img src="../assets/img/twitter.png" class="__sociaimgs"/>
                      </a>
                      <a :href="`${user.profile.instagram_url}`" target="_blank" v-if="user.profile.instagram_url">
                        <img src="../assets/img/instagram.png" class="__sociaimgs" />
                      </a>
                      <a :href="`${user.profile.linkedin_url}`" target="_blank" v-if="user.profile.linkedin_url">
                        <img src="../assets/img/linkedin.png" class="__sociaimgs" />
                      </a>
                      <a :href="`${user.profile.facebook_url}`" target="_blank" v-if="user.profile.facebook_url">
                        <img src="../assets/img/facebook.png" class="__sociaimgs"/>
                      </a>
                    </div>
                  </div>
                  <hr />
                </template>
              </div>
            </div>
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-7">
              <div class="__movleft">
                <p style="width: 100%;height: 58px;display: flex;align-items: center;justify-content: center;background: #ff0000a3;color: #ffffff;border-radius: 3px;" v-show="profileIsIncomplete">Please complete the following steps for your account to be active.</p>
                <hr />
                <div class="row">
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-6">
                    <p class="__nbio">About Me</p>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-6">
                    <a
                      data-toggle="modal"
                      href="#modal-id-about"
                      class="btn __neditbtn"
                      @click.prevent="showAboutModal = true"
                    >
                      Edit
                    </a>
                  </div>
                </div>
                <hr />
                <p class="profiledetail__name">
                  <span>Hey, I'm</span>
                  <template v-if="user">
                    {{ user.fullName }}
                  </template>
                </p>
                <div class="profiledetail__status">
                   <div class="__proflex">
                    <p class="profiledetail__status-exper">
                      <img
                        :src="require('../assets/img/briefcase.png')"
                        class="profiledetail__status-img"
                      />
                      Work Preferences:
                      <span v-if="user && user.profile.work_preferences === 'full-time'">
                        Full time
                      </span>
                      <span v-if="user && user.profile.work_preferences === 'part-time'">
                        Contract
                      </span>
                      <span
                        class="add_work_pref_btn"
                        v-if="user && !user.profile.work_preferences"
                        @click.prevent="showBioModal = true"
                      >
                        <a
                          href=""
                        >add</a>
                      </span>
                    </p>

                    <p class="profiledetail__status-exper">
                      <img
                        :src="require('../assets/img/epushpin.png')"
                        class="profiledetail__status-img"
                      />
                      Location:
                      <span v-if="user && !user.profile.country">N/A</span>
                      <span v-if="user && user.profile.country">
                        {{ `${user.profile.city}, ${user.profile.country}` }}
                      </span>
                    </p>
                  </div>

                  <div class="__proflex">
                    <p class="profiledetail__status-exper">
                      <img
                        :src="require('../assets/img/currency.png')"
                        class="profiledetail__status-img"
                      />
                      charges: 
                      <span v-if="user && user.profile.work_rate">
                        {{ `₦${user.profile.work_rate}` }} (Per)Hour
                      </span>
                      <span v-if="user && !user.profile.work_rate">N/A</span>
                    </p>
                  </div>
                </div>

                <hr />
                <div class="row">
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-6">
                    <p class="__nbio">Bio</p>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-6">
                    <a
                      data-toggle="modal"
                      href="#modal-id-bio"
                      class="btn __neditbtn"
                      @click.prevent="showBioModal = true"
                    >Edit</a>
                  </div>
                </div>
                <hr />

                <div class="profiledetail__more">
                  <p class="profiledetail__biodetail" v-if="user && !user.profile.bio">
                    Let people know a litle about you. Click on edit to get
                    started
                  </p>
                  <p class="profiledetail__biodetail" v-if="user && user.profile.bio">
                    {{ user.profile.bio.length > 500 ?  `${user.profile.bio.substr(0, 500)}...`: user.profile.bio }}
                  </p>
                </div>

                <hr />
                <div class="row">
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-6">
                    <p class="__nbio">Skills</p>
                  </div>
                </div>
                <hr />

                <div class="__emptyboxdow">
                  <p
                    class="profiledetail__biodetail"
                    v-if="user && !user.profile.skills"
                  >
                    You have not added any expertises, click on edit to add your
                    area(s) of expertise
                </p>
                <div v-if="user && user.profile.skills">
                  <template
                    v-for="(skill, index) of user.profile.skills.split(',')"
                  >
                    <Chip :label="skill" :isCloseable="false" :key="index" />
                  </template>
                </div>
                </div>

                <hr />
                <div class="row">
                  <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-xs-6">
                    <p class="__nbio">Socials</p>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-6">
                    <a
                      data-toggle="modal"
                      href="#modal-id-bio"
                      class="btn __neditbtn"
                      @click.prevent="showSocialModal = true"
                    >Edit</a>
                  </div>
                </div>
                <hr />

                <div class="row">
                  <div class="col-xl-7 col-lg-7 col-md-4 col-sm-4">
                    <p class="__nbio">Meetings and Events</p>
                  </div>
                  <div class="col-xl-5 col-lg-5 col-md-8 col-sm-8">
                    <p class="__navche">
                      Available for meetings&nbsp;&nbsp;&nbsp;
                      <input
                        type="checkbox"
                        class="switch_1"
                        :checked="user && user.profile.work_available === 1"
                      />
                    </p>
                  </div>
                </div>
                <hr />
                <div class="mentocard">
                  <div class="row">
                    <div class="col-xl-7 col-lg-6 col-md-6 col-sm-6">
                      <p class="__nbioin">Available Session</p>
                    </div>
                    <div class="col-xl-5 col-lg-6 col-md-6 col-sm-6">
                      <a
                        data-toggle="modal"
                        href="#modal-id-mentorship"
                        class="btn __neditbtn"
                        @click.prevent="showMentorshipModal = true"
                      >Add a new event</a>
                    </div>
                  </div>
                  <hr />
                  <p class="profiledetail__biodetail" v-if="mentorshipSessions && !mentorshipSessions.length">
                  Add a mentorship session
                </p>
                <template v-if="mentorshipSessions && mentorshipSessions.length">
                  <template v-for="(session, index) of mentorshipSessions">
                    <div :key="session.id">
                   <div class="row">
                    <div class="col-xl-11 col-lg-11 col-md-11 col-sm-10">
                      <p class="mentocard__cofp">
                        {{ session.name }}
                      </p>
                    </div>
                    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2">
                      <a href="" @click.prevent="confirmDelete(session)">
                        <img
                          :src="require('../assets/img/deleteimg.png')"
                          class="mentocard__deletement"
                      /></a>
                    </div>
                  </div>
                  
                  <div class="mentocard__flexdiv">
                    <p class="mentocard__flexdiv--disc">
                      <img :src="require('../assets/img/duration.png')" /> Duration:
                      <span>{{ session.duration }}Hour</span>
                    </p>

                    <p class="mentocard__flexdiv--disc">
                      <img :src="require('../assets/img/epushpin.png')" /> Location:
                      <template
                        v-if="session.location.toLowerCase().includes('digital')
                          && session.link.toLowerCase().includes('google')"
                        >
                        <img :src="require('../assets/img/google-meet.png')" style="marginRight: 3px" />
                      </template>
                      <template
                        v-if="session.location.toLowerCase().includes('digital')
                          && session.link.toLowerCase().includes('zoom')"
                        >
                        <img :src="require('../assets/img/zoom.png')" style="marginRight: 3px">
                      </template>
                      <template
                        v-if="!session.location.toLowerCase().includes('digital')"
                        >
                        <img :src="require('../assets/img/inperson.png')" style="marginRight: 3px" />
                      </template>
                      <span>{{session.location}}</span>
                    </p>

                    <p class="mentocard__flexdiv--disc">
                      <img src="../assets/img/currency.png" style="marginRight: 3px" /> Charges:
                      <span>{{ session.payment_mode }}</span>
                    </p>
                  </div>
                    <a href="#" class="btn secondary_btn" @click.prevent="setSessionToEdit(session)">Edit</a>
                    <hr v-if="index + 1  !== mentorshipSessions.length">
                    </div>
                  </template>
                </template>
                </div>
              </div>
            </div>
          </div>

          <div class="__spacet7p"></div>
        </div>
      </div>
    </section>

        <UpdateAboutUserModal
          :shouldShow="showAboutModal"
          @closeModal="showAboutModal = false"
          :user="userData"
          :slug="slug"
        />

        <UpdateUserBioModal
          :shouldShow="showBioModal"
          @closeModal="showBioModal = false"
          :user="userData"
        />

        <AddMentorshipSessionModal
          :shouldShow="showMentorshipModal"
          @closeModal="showMentorshipModal = false"
          :user="userData"
        />

        <DeleteDialog 
          :shouldShow="showDeleteDialog"
          :sessionToDelete="sessionToDelete"
          itemType="Session"
          @closeModal="showDeleteDialog = false"
          @deleteItem="handleDelete"
          :showDeleteLoader="showDeleteLoader"
        />  

        <UpdateMentorshipSessionModal 
          :shouldShow="showUpdateMentorshipModal"
          :selectedSession="selectedSession"
          @closeModal="showUpdateMentorshipModal = false"
        />

        <AddSocialsModal 
          :shouldShow="showSocialModal"
          :selectedSession="selectedSession"
          @closeModal="showSocialModal = false"
          :user="userData"
          :slug="slug"
        />


  
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import topHeader from "../components/topHeader.vue";
import profileCarousel from "../components/profileCarousel.vue";
import UserMixin from "../mixins/usersMixins";
import Chip from "../components/chip";
import UpdateAboutUserModal from "./common/UpdateAboutUserModal";
import UpdateUserBioModal from "./common/UpdateUserBioModal";
import AddMentorshipSessionModal from "./common/AddMentorshipSessionModal";
import UpdateMentorshipSessionModal from "./common/UpdateMentorshipSessionModal";
import DeleteDialog from "./common/DeleteDialog";
import AddSocialsModal from './common/AddSocialsModal';

import { errorHandler } from "../../utils/helpers";

export default {
  name: "ProfileEditState",
  mixins: [UserMixin],
  components: {
    topHeader,
    profileCarousel,
    Chip,
    UpdateAboutUserModal,
    UpdateUserBioModal,
    AddMentorshipSessionModal,
    DeleteDialog,
    UpdateMentorshipSessionModal,
    AddSocialsModal
  },
  data() {
    return {
      showAboutModal: false,
      showBioModal: false,
      showMentorshipModal: false,
      slug: this.$route.params.userSlug,
      showDeleteDialog: false,
      sessionToDelete: "",
      selectedSessionSlug: null,
      showDeleteLoader: false,
      showUpdateMentorshipModal: false,
      selectedSession: "",
      profileIsIncomplete: false,
      showSocialModal: false,
      userData: null
    };
  },
  computed: mapGetters([
    "user",
    "mentorshipSessions",
    "userProfile"
  ]),
  methods: {
    ...mapActions([
      "updateUserBio",
      "createSession",
      "fetchSessions",
      'fetchSingleUserProfile',
      "deleteSession",
    ]),

    async setSessionToEdit(selectedSession) {
      this.showUpdateMentorshipModal = true;
      this.selectedSession = selectedSession;
    },

    async confirmDelete(selectedSession) {
      this.showDeleteDialog = true;
      this.selectedSessionSlug = selectedSession.slug;
      this.sessionToDelete = selectedSession.name;
    },
    async handleDelete() {
      try {
        this.showDeleteLoader = true;
        const result = await this.deleteSession(`${this.selectedSessionSlug}`);

        this.$toast.success(`${result.message}`);
        this.showDeleteDialog = false;
        this.selectedSessionSlug = null;
      } catch (error) {
        errorHandler(error, this.$toast);
      } finally {
        this.showDeleteLoader = false;
      }
    },
  },
  async created() {
    this.time = new Date();
    const { userSlug } = this.$route.params;

    try {
      if (this.mentorshipSessions.length === 0) {
        await this.fetchSessions(`${userSlug}`);
      }
      const { data } = await this.fetchSingleUserProfile(`${userSlug}`);

      this.userData = data;
      const tempUser = { ...data };
      const profile = {...tempUser.profile };
      delete tempUser.profile;
      delete profile.resume;
      delete tempUser.token;

      const tempUserValues = Object.values(tempUser);
      const profileValues = Object.values(profile);
      const nullUserValues = !tempUserValues.every(value => value !== null);
      const nullProfileValues = !profileValues.every(value => value !== null);

      this.profileIsIncomplete = nullUserValues || nullProfileValues;
    } catch (error) {
      errorHandler(error, this.$toast);
    } finally {
      this.profile = { ...this.user.profile };
    }
  },
};
</script>

<style lang="scss">
.profile-edit-form-label {
  font-family: "CircularStd-Book";
}
.profile-edit-form-group {
  margin-bottom: 0.2rem !important;
}

.border-bottom {
  border-radius: 0 !important;
  border-bottom: 1px solid #9a9a9a;
}
.secondary_btn {
  display: inline-block;
  border: 1px solid #2357bb;
  margin-top: 1.5rem;
  padding: 7px 20px 7px 20px !important;
}

.add_work_pref_btn {
  text-decoration: underline;
  color: rgb(52, 52, 202) !important;
  font-size: 14px;
}
</style>
